$(function() {

	// Determine whether the browser supports overflow-scrolling
	function supportsOverflowScrolling() {
		return !($('html').hasClass('no-overflowscrolling'));
	}
	
	// Determine whether a popout is currently active
	function isPopoutsActive() {
		var activePopout = false;
		$(popouts).each(function(index, value) {
			if($('body').hasClass('popout-' + value + '-is-open')) activePopout = true;
		});
		return activePopout;
	}

	// Determine whether a popout is currently active
	function isPopoutActive(which) {
		var activePopout = false;
		if($('body').hasClass('popout-' + which + '-is-open')) activePopout = true;
		return activePopout;
	}
	
	// Close all active popouts
	function closeActivePopouts() {
		$(popouts).each(function(index, value) {
			closeActivePopout(value);
		});
	}

	//Close selected popout
	function closeActivePopout(which) {

		$('html').css('overflow-y','initial');
		if($('body').hasClass('popout-' + which + '-is-open'))
		{
			$('body').removeClass('popout-' + which + '-is-open');
			$('body').removeClass('js-full-popout');
		}
		$('.has-children').removeClass('show-sub-menu')
		$('.mobile-navigation-wrapper').removeClass('open-sub-menu');

		TweenMax.to($('#' + which), 0.4, {
			display: 'none',
			x: '-100%',
			onComplete: closeActivePopoutComplete,
			onCompleteParams: [which]
		});

		TweenMax.to($('.popout-bg'), 0.4, {
			display: 'none',
			opacity: 0
		});
		$('#'+which).removeClass('active');
		$('#'+which).removeClass('mod-popout-filter-reloaded');
		$('input[name=mobile_popout_id]').val("");

	}

	function closeActivePopoutComplete(which) {
		$('#' + which).removeClass('is-scrolled');
	}
	
	// Open a popout window
	function openPopout(which) {
		$('body').addClass('js-full-popout popout-' + which + '-is-open');
		$('html').css('overflow-y','hidden');
		// No overflow support? Set a fixed height and scroll page to the top
		if ($('html').hasClass('touchevents'))
		{
			//$('#popout-' + which + '-is-open').height($(document).height());
			$('body').scrollTop(0);
		}

		if($('body').scrollTop() > 80)
		{
			$('#' + which).addClass('is-scrolled');
		}

		TweenMax.to($('#' + which), 0.4, {
			display: 'block',
			x: '0%'
		});
		
		TweenMax.to($('.popout-bg'), 0.4, {
			display: 'block',
			opacity: 1
		});
		$('#form-secondary-filter-mobile input[name="mobile_popout_id"]').val(which);
		$('#form-secondary-filter input[name="mobile_popout_id"]').val(which);
	}

	var clickEventType = ((document.ontouchstart!==null)?'click':'touchstart'),
		popouts = [];

	$('.js-popout').each(function() {
		popouts.push($(this).attr('id'));
	});

	// Left button events
	$(document).on('click','.js-toggle-popout', function(e) {
		e.stopPropagation();
		e.preventDefault();
		$(document).scrollTop($('#header').outerHeight());

		var popoutId = $(this).data('popout-id');
		$(this).closest('.active').toggleClass('active');

		if($(this).attr('id') == 'mobile-header-trigger' && $('.popout').is(':visible'))
		{
			closeActivePopouts();
			//$('.js-toggle-popout').removeClass('is-active');
		}
		else if(!isPopoutActive(popoutId))
		{

			closeActivePopouts();

			openPopout(popoutId);
			
			if($(this).attr('id') == 'mobile-header-trigger')
			{
				//$(this).addClass('is-active');
			}
		}
		else
		{
			if($(this).attr('id') == 'mobile-header-trigger')
			{
				//$('.js-toggle-popout').removeClass('is-active');
			}
			else
			{
				closeActivePopout(popoutId);
			}
		}

		
	});

    $(document).on('click', '.js-close-active-popouts', function (e) {
        $('body').removeClass('js-full-popout');
		closeActivePopouts();
	    
		$('html, body').animate({
            scrollTop: $(document).find('#vehicle-results').offset().top
		}, 10);
	});

	// Ensure the popout menus handle their own touch events
	$('.popout').on('touchmove touchstart', function(e) {
		var ele = this,
			$ele = $(ele);
				
		// Ensure we don't scroll the page outside of the popout
		if (e.type == "touchstart")
		{
			if (ele.scrollTop === 0) {
				ele.scrollTop = 1;
			} else if (ele.scrollHeight === ele.scrollTop + ele.offsetHeight) {
				ele.scrollTop -= 1;
			}
		}
		
		if (e.type == "touchmove")
		{
			// No scrollbar? Then preventdefault to ensure we don't scroll outside
			if ($ele.get(0).scrollHeight < $ele.innerHeight())
				e.preventDefault();
		}
	});

	$(document).on('click', '.js-toggle-popout-sub-menu', function(e){
		e.preventDefault();

		var $this = $(this),
			$wrapper = $this.parents('.mobile-nav-list-item'),
			$subMenu = $this.next('.mobile-nav-sub');

		if($subMenu.is(':visible'))
		{
			$wrapper.removeClass('is-active');
			$subMenu.slideUp();
		}
		else
		{
			$wrapper.addClass('is-active');
			$subMenu.slideDown();
		}

	});

	$(document).on('click', '.mod-popout-filter label', function() {
		$(this).closest('label').addClass('checked');
	});
});